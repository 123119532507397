import React, { useState, useEffect } from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import { AboveBreakpointValue } from 'src/components/Breakpoints';
import { allowScroll, preventScroll } from 'src/libs/scroll';
import { navigate } from 'gatsby';
import Arrow from 'src/icons/Arrow';
import bergTextIcon from 'src/assets/icons/bergTextLogo.svg';
import BurgerButton from 'src/components/buttons/BurgerButton';
import CrossButton from 'src/components/buttons/CrossButton';
import HeaderCart from './HeaderCart';
import isLoggedIn from 'src/utils/isLoggedIn';
import Link from 'src/components/Link';
import MyPageIcon from 'src/icons/MyPageIcon';
import Navigation from './Navigation';
import Popup from './CartPopup';
import PropTypes from 'prop-types';
import Search from 'src/components/Search';
import SearchContext from 'src/components/Search/Context/Search';
import SearchIcon from 'src/icons/SearchIcon';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';

const HeaderWrapper = styled('div')`
    background-color: ${theme.color.white};
    color: ${theme.color.black};
    height: 50px;
    display: flex;
    border-bottom: 1px solid ${theme.color.black};
    padding: 0px 12px 0px 12px;
    position: fixed;
    width: 100%;
    z-index: 9999;

    ${below.tablet_s} {
        justify-content: flex-end;
    }

    ${above.tablet_s} {
        height: 52px;
        justify-content: space-between;
        padding: 0px 20px 0px 20px;
    }

    ${above.desktop_s} {
        padding: 0px 32px 0px 32px;
    }
`;

const DropDownWrapper = styled('div')`
    background-color: ${theme.color.white};
    color: ${theme.color.black};
    height: 50px;
    display: flex;
    border-bottom: solid 1px ${theme.color.black};
`;

const Logo = styled('img')`
    height: 20px;
    margin: auto 0px;
`;

const LogoWrapper = styled('a')`
    z-index: 1100;
    height: 100%;

    ${below.tablet_s} {
        background-color: ${theme.color.white};
        margin-right: auto;
    }

    ${above.tablet_l} {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: 140px;
    }
`;

const RightSideWrapper = styled('div')`
    z-index: 1100;
    background-color: ${theme.color.white};
    display: flex;
    order: 5;
`;

const NavigationWrapper = styled('div')`
    display: flex;
    order: 0;
    z-index: 1000;

    ${below.tablet_s} {
        position: absolute;
        margin-top: 50px;
        width: 100%;
        left: 0;
    }
`;

const Center = styled('div')`
    margin-top: 16px;

    ${above.tablet_s} {
        height: 100%;
        margin-top: 0px;
        display: flex;
        justify-content: center;
    }
`;

const BurgerMenu = styled('div')`
    ${above.tablet_s} {
        display: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
`;

const StyledMyPageIcon = styled(MyPageIcon)`
    opacity: 1;
    transition: opacity 0.2s;
    display: flex;
    justify-content: center;

    &.hide-icon {
        opacity: 0;
        pointer-events: none;
    }
`;

const MyPageLink = styled(Link)`
    color: ${theme.color.black};
    margin: auto;
    cursor: pointer;
    margin-right: 12px;

    ${below.tablet_s} {
        display: none;
    }

    ${above.desktop_s} {
        margin-right: 20px;
    }
`;

const CartWrapper = styled('div')`
    margin: auto;
    cursor: pointer;
`;

const SearchButton = styled('div')`
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 12px;

    ${above.desktop_s} {
        margin-right: 20px;
    }

    ${below.tablet_s} {
        display: none;
    }
`;

const SearchText = styled(Text)`
    margin: auto 0;
    margin-left: 8px;
`;

const CrossButtonWrapper = styled('div')`
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    top: 50px;
    right: 0;
    margin-top: 32px;
    margin-right: 32px;

    ${below.desktop_s} {
        display: none;
    }
`;

const MobileSearchInputWrapper = styled('form')`
    position: fixed;
    width: 100%;
    height: 50px;
    background-color: ${theme.color.white};
    border-bottom: 1px solid ${theme.color.black};
    z-index: 9000;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0px 12px 0px 12px;

    ${above.tablet_s} {
        display: none;
    }
`;

const MobileSearchInput = styled('input')`
    background-color: transparent;
    width: 100%;
    height: 100%;
    color: ${theme.color.black};
    margin-right: 12px;
    margin-left: 6px;
    font-family: 'SuisseIntL';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1em;

    &::placeholder {
        color: ${theme.color.black};
        font-family: 'SuisseIntL';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 1em;
    }

    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        line-height: 2.8em;
        color: ${theme.color.black};
        opacity: 1;
    }
`;

const InputSearchIconWrapper = styled('div')`
    height: 100%;
    position: relative;
    width: 12px;
`;

const StyledInputSearchIcon = styled(SearchIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const StyledInputCrossButton = styled(CrossButton)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const CustomCursorCover = styled('div')`
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 900;
    backdrop-filter: blur(${theme.blur.standard.mobile});

    ${above.desktop_s} {
        backdrop-filter: blur(${theme.blur.standard.desktop});
        cursor: url('/cursor/closeCursor.svg') 28 28, pointer;
    }
`;

const Header = ({ data }) => {
    const basket = data && data.basket;
    const navigation = data && data.navigation;

    const [mobileSearchString, setMobileSearchString] = useState(null);
    const [openSubMenu, setSubOpen] = useState(false);
    const [activePopup, setActivePopup] = useState(false);
    const showCart = activePopup === 'cart';
    const showSearch= activePopup === 'search';
    const mobileNavOpen = activePopup === 'mobileNav';

    const mouseEnterOpenMenu = (index) => {
        setSubOpen(index);
        setActivePopup('mobileNav');
    };

    const closeMobileNav = () => {
        setActivePopup(false);
    };

    const closeCartPopup = () => {
        setActivePopup(false);
    };

    const toggleMobileNav = () => {
        setActivePopup( activePopup === 'mobileNav' ? false : 'mobileNav' );
    };

    const toggleSearch = () => {
        setActivePopup(activePopup === 'search' ? false : 'search');
    };

    const openCartPopup = () => {
        setActivePopup('cart');
    };

    useEffect(() => {
        if (activePopup !== 'mobileNav' && openSubMenu) {
            setSubOpen(false);
        }

        const isDesktop = window.innerWidth > AboveBreakpointValue('mobile_l');

        if (!activePopup || isDesktop) {
            allowScroll();
        } else {
            preventScroll();
        }

     }, [activePopup, openSubMenu]);

    return (
        <>
            <HeaderWrapper>
                <LogoWrapper href="/">
                    <Center>
                        <Logo src={bergTextIcon} />
                    </Center>
                </LogoWrapper>
                {navigation && (
                    <NavigationWrapper>
                        <Navigation openSubMenu={openSubMenu} setSubMenu={setSubOpen} navigation={navigation} isOpen={mobileNavOpen} closeMobileNav={closeMobileNav} mouseEnterOpenMenu={mouseEnterOpenMenu}/>
                    </NavigationWrapper>
                )}
                <RightSideWrapper className="right">
                    <MyPageLink src={isLoggedIn ? '/account' : '/login'}>My page</MyPageLink>
                    <SearchButton onClick={toggleSearch}>
                        <SearchIcon />
                        <SearchText as="span">Search</SearchText>
                    </SearchButton>
                    <BurgerMenu onClick={toggleMobileNav}>
                        <BurgerButton active={activePopup === 'mobileNav'} />
                        <StyledMyPageIcon filled={isLoggedIn} className={activePopup === 'mobileNav' ? 'hide-icon' : null}/>
                    </BurgerMenu>
                    <CartWrapper onClick={openCartPopup}>
                        <HeaderCart basket={basket} />
                    </CartWrapper>
                </RightSideWrapper>
            </HeaderWrapper>
            <DropDownWrapper />
            <MobileSearchInputWrapper
                onSubmit={e => {
                    e.preventDefault();
                    const searchValue = e.target.children[1].value;
                    toggleSearch();
                    navigate(`/search?term=${searchValue}`);
                }}
            >
                <InputSearchIconWrapper>
                    {showSearch ? <StyledInputCrossButton rotated onClick={toggleSearch} /> : <StyledInputSearchIcon />}
                </InputSearchIconWrapper>
                <MobileSearchInput
                    placeholder="Search"
                    maxLength={100}
                    onChange={e => setMobileSearchString(e && e.target.value)}
                    onFocus={!showSearch ? toggleSearch : null}
                />
                <Link
                    src={`/search?term=${mobileSearchString === null ? '' : mobileSearchString}`}
                    state={{/* passing empty state to make page re-render */}}
                    onClick={toggleSearch}
                >
                    <Arrow />
                </Link>
            </MobileSearchInputWrapper>
            {showSearch && (
                <SearchContext.Provider value={toggleSearch}>
                    <Search mobileSearchString={mobileSearchString} />
                    <CrossButtonWrapper>
                        <CrossButton rotated onClick={toggleSearch} />
                    </CrossButtonWrapper>
                    <CustomCursorCover onClick={toggleSearch} />
                </SearchContext.Provider>
            )}
            {showCart && <Popup closeCartPopup={closeCartPopup} />}
        </>
    );
};

Header.propTypes = {
    data: PropTypes.shape({
        basket: PropTypes.object.isRequired,
        navigation: PropTypes.array.isRequired,
        siteTitle: PropTypes.string,
    }),
};

Header.defaultProps = {
    data: {
        siteTitle: '',
    },
};

export default Header;
